
@media (max-width: 500px) { 
    .signatureCanvas {
        width:200px;
        height: 100px;
    }
}

@media only screen and (min-width: 501px) {
    .signatureCanvas {
        width:400px;
        height: 200px;
    }
    
  }